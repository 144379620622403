<template>
  <NuxtLink
      v-if="showComponent"
      :key="fullname + weapon"
      :to="getWeaponLink(weapon || '')"
      class="category-link"
  >
    <img :alt="(fullname || '') + ' category item'"
         :src="getImageFromWaxpeer( fullname || '', '120:120')"
         class="link-icon"
         height="50"
         loading="lazy"
         width="50"
    />
    <span class="link-name">{{ $t('filters.weapon.' + weapon) }}</span>
    <span class="link-border"></span>
  </NuxtLink>
</template>

<script lang="ts" setup>
const props = defineProps<{ fullname: string, weapon: string }>()

const {showComponent} = await useDelayShow()
</script>

<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;
@use 'sass:color';

.category-link {
  display: inline-flex;
  align-items: center;
  padding: 4px 16px;
  width: 100%;
  gap: 16px;
  border-radius: 0 8px 8px 0;

  &:hover, &.router-link-exact-active {
    background: color.adjust(#1B1F28, $lightness: 5%);

    .link-name {
      color: #ffffff;
    }
  }

  .link-icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    //padding: 8px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(#9080F9, 0.2) 0%, rgba(#9080F9, 0) 100%);;
  }

  .link-name {
    color: #92A1BF;
  }

  //@media screen and (max-width: 1115px) {
  //  .link-icon, &:after {
  //    width: 4rem;
  //    height: 4rem;
  //    padding: 0;
  //  }
  //
  //  .link-name {
  //    line-height: 4.3rem;
  //  }
  //}
}
</style>
