<template>
  <ul id="other-menu">
    <li
        v-for="parent in parentCategories"
        :key="parent"
        class="other-menu__label"
    >
      <span class="other-menu__label-text">{{ t('categories.' + parent) }}</span>
      <ul class="inner-ul">
        <li
            v-for="inner in innerCategories[parent.toLowerCase()]"
            :key="inner.full_name"
            class="inner-ul__item"
        >
          <NuxtLink
              v-if="inner.full_name && !inner.image"
              :to="getWeaponLink(inner.weapon || '')"
              class="inner-ul__link"
          >
            <img
                :alt="inner.full_name + ' category item'"
                :src="getImageFromWaxpeer(inner.full_name || '', '120:120')"
                class="inner-ul__icon"
                height="50"
                loading="lazy"
                width="50"
            />
            <span class="inner-ul__name">{{ t('filters.weapon.' + inner.weapon) }}</span>
          </NuxtLink>
          <NuxtLink
              v-else
              :to="localePath('/' + inner.full_name)"
              class="inner-ul__link"
          >
            <img
                :alt="inner.weapon + ' category item'"
                :src="inner.image"
                class="inner-ul__icon"
                :class="inner.weapon"
                height="50"
                loading="lazy"
                width="50"
            />
            <span class="inner-ul__name">{{ t(inner.weapon || '') }}</span>
          </NuxtLink>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type {Item} from "~/graphql/generated/schema-types";

const props = defineProps<{
  categories: Item[]
}>()

const {t} = useI18n()
const localePath = useLocalePath()

const parentCategories = ['cosmetics', 'cases_and_capsules', 'Other']

const innerCategories = computed(() => {
  return {
    cosmetics: ['Stickers', 'Weapon Charms', 'Graffities', 'Music Kits', 'Patches'].map((category) =>
        props.categories.find((item) => item.weapon === category) || {} as Item
    ),
    cases_and_capsules: ['Case', 'Crates', 'Autograph Capsule', 'Patch Capsule', 'Sticker Capsule', 'Music Kit Box', 'Graffiti', 'Souvenir', 'Pins'].map((category) =>
        props.categories.find((item) => item.weapon === category) || {} as Item
    ),
    other: [
      ...(['Agents', 'Keys', 'Tools', 'Collectibles'].map((category) =>
          props.categories.find((item) => item.weapon === category) || {} as Item
      )),
      {
        full_name: 'tournaments',
        weapon: 'tournaments',
        image: '/img/trophy.png',
      },
      {
        full_name: 'teams',
        weapon: 'teams_label',
        image: '/img/trophy-teams.png',
      },
      {
        full_name: 'markets',
        weapon: 'all_markets',
        image: '/img/markets.svg',
      }
    ] as Item[]
  }
})
</script>

<style lang="scss" scoped>
@use 'sass:color';

#other-menu {
  background: #1B1F28;
  //background: red;
  font-size: 14px;
  padding: 0;
  border-radius: 12px;

  /* width */
  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    padding: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333B4D;
    border-radius: 4px;
    border: 4px solid transparent; // Создает эффект узкого `thumb`
    background-clip: padding-box; // Центрирует `thumb` внутри скроллбара
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: color.adjust(#333B4D, $lightness: 5%);
  }

  @media screen and (max-width: 1115px) {
    background: transparent;
  }

  &ul, ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .other-menu {
    &__label {
      &-text {
        font-weight: 600;
        color: #9080F9;
        display: inline-block;
        padding: 12px 12px 8px 12px;
        text-transform: uppercase;
      }
    }
  }

  .inner-ul {
    &__link {
      display: inline-flex;
      align-items: center;
      padding: 4px 16px;
      width: 100%;
      gap: 16px;
      border-radius: 0 8px 8px 0;

      &:hover, &.router-link-exact-active {
        background: color.adjust(#1B1F28, $lightness: 5%);

        .inner-ul__name {
          color: #ffffff;
        }
      }
    }

    &__name {
      color: #92A1BF;
    }

    &__icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      //padding: 8px;
      background: radial-gradient(50% 50% at 50% 50%, rgba(#9080F9, 0.2) 0%, rgba(#9080F9, 0) 100%);
      &.all_markets{
        width: 50px;
        height: 50px;
        margin: -10px;
      }
    }
  }

}
</style>
